export const styles = {
    pageContainer: {
        maxWidth: '1200px',
        margin: '16px auto',
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '24px'
    },
    detailsContainer: {
        padding: '24px',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
    },
    mapContainer: {
        padding: '24px',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
    },
    title: {
        marginBottom: '24px',
        fontSize: '20px',
        fontWeight: 500
    },
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '8px',
        marginBottom: '16px'
    },
    label: {
        fontWeight: 500
    },
    value: {
        color: '#333'
    },
    sectionTitle: {
        marginTop: '32px',
        marginBottom: '16px',
        fontSize: '18px',
        fontWeight: 500
    },
    starContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px'
    },
    percentageText: {
        marginLeft: '8px'
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: '16px'
    },
    loadingSpinner: {
        width: '32px',
        height: '32px',
        border: '4px solid #f3f3f3',
        borderTop: '4px solid #3498db',
        borderRadius: '50%',
        animation: 'spin 1s linear infinite'
    },
    starWrapper: {
        position: 'relative' as const,
        width: '20px',
        height: '20px'
    },
    starBase: {
        position: 'absolute' as const
    },
    starFill: {
        position: 'absolute' as const,
        overflow: 'hidden'
    },
    mapContent: {
        width: '100%',
        height: '600px',
        borderRadius: '4px',
        overflow: 'hidden'
    },
    deleteButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        padding: '10px 20px',
        cursor: 'pointer',
        backgroundColor: '#f44336',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        fontSize: '14px',
    }
} as const;