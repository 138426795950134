import React, {ReactNode} from 'react';
import {MaxWidthContainer, RouteHeader, Routes} from '@easerill/pixida-group-ui';
import Footer from '@chaptesi/licenses/components/Footer';
import useStateAuth from '../../hooks/state/useStateAuth';
import useCustomerHive from '../../hooks/api/useCustomerHive';
import styles from './Layout.module.scss';

/**
 * Properties for the Layout component.
 */
type LayoutProps = {
	children?: ReactNode;
	routes: Routes;
	userRoles: string[];
};

/**
 * A layout component that provides a common structure for pages.
 * @param {LayoutProps} props - The props for the Layout component.
 * @returns {ReactNode} The rendered layout component.
 */
const Layout = ({children, routes, userRoles}: LayoutProps) => {
	const {user} = useStateAuth();
	const {signOut} = useCustomerHive();

	return (
		<div className={styles.container}>
			<RouteHeader
				username={user?.name ?? 'Guest'}
				greetingText='Hallo, '
				routes={routes}
				userRoles={userRoles}
				showSeparator={true}
				userMenus={[
					{
						label: 'Logout',
						/**
						 * Handles the logout action.
						 */
						onClick: () => {
							signOut().then(() => {
								window.location.reload();
							});
						}
					}
				]}
			/>
			<div className={styles.main}>
				<MaxWidthContainer>
					<>{children}</>
				</MaxWidthContainer>
			</div>
			<Footer className={styles.footer} />
		</div>
	);
};

export default Layout;
