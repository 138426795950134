import axios from './axios';
import {HIVE_URL} from '../constants';
import {User} from '../redux/Auth';

/**
 * Calls the API to retrieve the list of users .
 * @returns a list of users
 */
export const listUsers = async (): Promise<User[]> => {
	const response = await axios.get(`${HIVE_URL}/v1/users`);
	return response.data;
};
