import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ColumnConfig, Table, useSnackbar, pixidaTheme } from '@easerill/pixida-group-ui';
import { Trip, Vehicle, ListTripsResponse } from '@sumo/types';
import { listTrips } from '../../api/trip';
import { Stack, Grid, Paper, CircularProgress } from '@mui/material';
import { DriverScore } from "./components/DriverScore";
import availableVehicles from "../Trips/availableVehicles.json";
import useStateAuth from "../../hooks/state/useStateAuth";
import { useSearchParams } from 'react-router-dom';

export const getVehicleDescription = (vehicleId: string, vehicles: Vehicle[]): string => {
    const vehicle = vehicles.find(v => v.number === vehicleId);
    if (vehicle) {
        const typeCapitalized = vehicle.type === "bus" ? "Bus" : "Tram";
        return `${vehicle.number} ${typeCapitalized} ${vehicle.city}`;
    }
    return vehicleId;
};

export const DriverTripPage = () => {
    const { user } = useStateAuth();
    const { triggerSnackbar } = useSnackbar();
    const [trips, setTrips] = useState<Trip[]>([]);
    const [loading, setLoading] = useState(true);
    const vehicles: Vehicle[] = availableVehicles;
    const [searchParams] = useSearchParams();
    const driverId = searchParams.get('driverId') || user?.username;

    const driverName = trips.length > 0 ? trips[0].driverName : '';

    const getHeaderTitle = () => {
        if (!driverId || driverId === user?.username) {
            return "Meine Fahrten";
        }
        return driverName ? `${driverName}s Fahrten` : "Fahrten";
    };

    useEffect(() => {
        setLoading(true);
        listTrips()
            .then((fetchedTrips: ListTripsResponse) => {
                const driverTrips = fetchedTrips.trips.filter(trip => trip.driverId === driverId);
                setTrips(driverTrips);
            })
            .catch(() => {
                triggerSnackbar('Es gab einen Fehler beim Abrufen der Fahrten', 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    }, [driverId]);


    const tableHeaderConfig: ColumnConfig<Trip>[] = [
        {
            id: 'name',
            label: 'Fahrtenname',
            width: '25%',
            render: (rowData) => (
                <span style={{ color: pixidaTheme.palette.primary.main }}>
                <Link to={`/trips/${rowData.data.tripId}`}>
                    {rowData.data.name}
                </Link>
            </span>
            )
        },
        {
            id: 'assignedVehicle',
            label: 'Fahrzeug',
            width: '25%',
            render: (rowData) => (
                <span>{getVehicleDescription(rowData.data.assignedVehicle, vehicles)}</span>
            )
        },
        {
            id: 'startTime',
            label: 'Startzeit',
            width: '20%',
            render: (rowData) => (
                <span>{new Date(rowData.data.startTime).toLocaleString("de-DE")}</span>
            )
        },
        {
            id: 'scorePercent',
            label: 'Bewertung',
            width: '15%',
            render: (rowData) => (
                <span>{rowData.data.scorePercent?.toFixed(0)} %</span>
            )
        }
    ];

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
                <Paper sx={{ p: 2 }}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ mb: 2 }}
                    >
                        <h2 style={{ margin: 0 }}>{getHeaderTitle()}</h2>
                    </Stack>

                    {loading ? (
                        <div style={{ display: "flex", justifyContent: "center", padding: 16 }}>
                            <CircularProgress color="inherit" />
                        </div>
                    ) : (
                        <>
                            {trips.length > 0 ? (
                                <Table
                                    title=""
                                    columnsConfig={tableHeaderConfig}
                                    data={trips}
                                    rowsPerPageOptions={[10, 25, 50]}
                                    showBorder={false}
                                    hideToolbar={true}
                                    defaultOrderBy="startTime"
                                    defaultOrder="desc"
                                />
                            ) : (
                                <div style={{ padding: 16 }}>Keine Fahrten gefunden.</div>
                            )}
                        </>
                    )}
                </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
                <Paper sx={{ p: 2 }}>
                    <DriverScore />
                </Paper>
            </Grid>
        </Grid>
    );
};