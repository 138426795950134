import React, {useEffect, useState} from 'react';
import {Paper} from '@mui/material';
import {Star} from 'lucide-react';
import {getTripDetail, deleteTrips} from '../../api/trip';
import {GetTripDetailsResponse, Trip, Vehicle} from '@sumo/types';
import {useParams} from 'react-router-dom';
import {useSnackbar} from '@easerill/pixida-group-ui';
import {styles} from './TripDetails.styles';
import RenderTripMap from './RenderTripMap';
import {getVehicleDescription} from './TripPage';
import availableVehicles from './availableVehicles.json';
import {useNavigate} from 'react-router-dom';
import useStateAuth from '../../hooks/state/useStateAuth';

export const TripDetails = () => {
	const {triggerSnackbar} = useSnackbar();
	const [trip, setTrip] = useState<Trip>();
	const [tripMapData, setTripMapData] = useState<any>();
	const [mapDataLoaded, setMapDataLoaded] = useState<boolean>(false);
	const [mapUrl, setMapUrl] = useState<string>('');
	const vehicles: Vehicle[] = availableVehicles;
	const navigate = useNavigate();

	const {tripId} = useParams();
	const {user} = useStateAuth();

	const allowedGroups = ['Root', 'Admin', 'FleetManager', 'FleetUser'];
	const isAuthorized = user?.groups?.some((group) => allowedGroups.includes(group));
	const isTechnician = user?.groups?.[0] === 'Technician';

	useEffect(() => {
		if (tripId) {
			getTripDetail(tripId)
				.then((tripDetails: GetTripDetailsResponse) => {
					setTrip(tripDetails.trip);
					setMapUrl(tripDetails.mapUrl);
					setMapDataLoaded(true);
				})
				.catch((err) => {
					triggerSnackbar('There was an error loading the trip details', 'error');
				});
		}
	}, [tripId]);

	useEffect(() => {
		if (mapDataLoaded) {
			fetch(mapUrl, {method: 'GET'})
				.then((response) => response.json())
				.then((data) => {
					setTripMapData(data);
				})
				.catch((error) => console.error('Error loading JSON data:', error));
		}
	}, [mapDataLoaded]);

	if (!trip) {
		return (
			<div style={styles.loadingContainer}>
				<div style={styles.loadingSpinner} />
			</div>
		);
	}

	const deleteTripApi = (tripIdin: string) => {
		deleteTrips([tripIdin])
			.then((deletedTrips: string[]) => {
				const deletedTripsList = deletedTrips.join(', ');
				triggerSnackbar(
					`Die Fahrt wurde erfolgreich gelöscht: ${deletedTripsList}`,
					'success'
				);
				navigate('/trips');
			})
			.catch((err) => {
				console.error('Error deleting trips:', err);
				triggerSnackbar('Es gab ein Problem beim Löschen der Fahrt.', 'error');
			});
	};

	const handleDelete = () => {
		if (tripId) deleteTripApi(tripId);
	};

	const formatDate = (date: string) => {
		return new Date(date).toLocaleString('de-DE', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
			hour: '2-digit',
			minute: '2-digit'
		});
	};

	const renderStars = (score: number) => {
		const stars = [];
		const normalizedScore = Math.min(100, Math.max(0, score || 0));

		for (let i = 0; i < 5; i++) {
			const starPercentage = (normalizedScore - i * 20) / 20;
			const fillAmount = Math.min(Math.max(starPercentage, 0), 1);

			stars.push(
				<div key={i} style={styles.starWrapper}>
					<Star style={{...styles.starBase, color: '#D3D3D3'}} size={20} fill="none" />
					<div
						style={{
							...styles.starFill,
							width: `${fillAmount * 100}%`
						}}
					>
						<Star style={{color: '#FFD700'}} size={20} fill="currentColor" />
					</div>
				</div>
			);
		}
		return <div style={styles.starContainer}>{stars}</div>;
	};

	const formatPercentage = (value: number) => {
		return Math.round(value || 0);
	};

	const getVehicleDetails = () => {
		const vehicle = vehicles.find(v => v.number === trip.assignedVehicle);
		return vehicle || null;
	};

	const vehicleDetails = getVehicleDetails();

	return (
		<div style={{...styles.pageContainer, position: 'relative'}}>
			{isAuthorized ? (
				<button onClick={handleDelete} style={styles.deleteButton}>
					Delete Trip
				</button>
			) : null}

			<Paper style={styles.detailsContainer} elevation={3}>
				<div style={styles.title}>
					{'Fahrtdetails'}
				</div>

				<div style={styles.gridContainer}>
					{!isTechnician && (
						<>
							<div style={styles.label}>Fahrer:</div>
							<div style={styles.value}>{trip.driverName}</div>
						</>
					)}

					<div style={styles.label}>Fahrzeug:</div>
					<div style={styles.value}>
						{getVehicleDescription(trip.assignedVehicle, vehicles)}
					</div>

					{isTechnician && vehicleDetails && (
						<>
							<div style={styles.label}>Hersteller:</div>
							<div style={styles.value}>{vehicleDetails.manufacturer}</div>

							<div style={styles.label}>Stadt:</div>
							<div style={styles.value}>{vehicleDetails.city}</div>

							<div style={styles.label}>Seriennummer:</div>
							<div style={styles.value}>{vehicleDetails.serial}</div>

							<div style={styles.label}>Energiemessung:</div>
							<div style={styles.value}>{vehicleDetails.energyLabel}</div>
						</>
					)}

					<div style={styles.label}>Fahrtbeginn:</div>
					<div style={styles.value}>{formatDate(trip.startTime)}</div>

					<div style={styles.label}>Fahrtende:</div>
					<div style={styles.value}>{formatDate(trip.endTime)}</div>

					<div style={styles.label}>Fahrtstrecke:</div>
					<div style={styles.value}>{trip.distanceKm} km</div>

					<div style={styles.label}>Haltestellen:</div>
					<div style={styles.value}>{trip.stopCount || 0}</div>
				</div>

				{!isTechnician && (
					<>
						<div style={styles.sectionTitle}>Fahrtbewertung:</div>

						<div style={styles.gridContainer}>
							<div style={styles.label}>Gesamt:</div>
							<div style={styles.starContainer}>
								{renderStars(trip.scorePercent || 0)}
								<span style={styles.percentageText}>
									({formatPercentage(trip.scorePercent)}%)
								</span>
							</div>

							<div style={styles.label}>Starkes Beschleunigen:</div>
							<div style={{...styles.value, display: 'flex', gap: '20px'}}>
								<span>x{trip.countEvtAccell}</span>
								<span>({formatPercentage(trip.scoreAccell)} Punkte Abzug)</span>
							</div>

							<div style={styles.label}>Starkes Bremsen:</div>
							<div style={{...styles.value, display: 'flex', gap: '20px'}}>
								<span>x{trip.countEvtBreaking}</span>
								<span>({formatPercentage(trip.scoreBreaking)} Punkte Abzug)</span>
							</div>

							<div style={styles.label}>Mögliche Energieeinsparung:</div>
							<div style={{...styles.value, display: 'flex', gap: '20px'}}>
								<span>x{trip.countEvtEnergy}</span>
								<span>({formatPercentage(trip.scoreEnergy)} Punkte Abzug)</span>
							</div>
						</div>
					</>
				)}
			</Paper>

			<Paper style={styles.mapContainer} elevation={3}>
				<div style={styles.title}>Fahrtstrecke</div>
				<div style={styles.mapContent}>
					<RenderTripMap data={tripMapData} />
				</div>
			</Paper>
		</div>
	);
};

export default TripDetails;