import React, {useEffect} from 'react';
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute';
import {User} from '../redux/Auth';
import useStateAuth from '../hooks/state/useStateAuth';
import useCustomerHive from '../hooks/api/useCustomerHive';
import {useDispatch} from 'react-redux';
import {Routes as PixidaRoutes} from '@easerill/pixida-group-ui';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './AppRoute.module.scss';
import {TripPage} from '../pages/Trips';
import { TripDetails } from '../pages/Trips/TripDetails';

export const routes: PixidaRoutes = {
	trips: {
		uri: '/trips',
		label: 'Fahrten',
		roles: ['Root', 'Admin', 'FleetManager', 'Technician', 'FleetUser', 'Driver']
	}
};


const AppRoutes = () => {
	const {user, setUser} = useStateAuth();
	const {authenticate, clearLocationUrl} = useCustomerHive();
	const dispatch = useDispatch();

	useEffect(() => {
		if (user === undefined || user === null) {
			authenticate().then((authenticatedUser: User | null) => {
				setUser(authenticatedUser);
				clearLocationUrl();
			});
		}
	}, [user, dispatch, authenticate, setUser, clearLocationUrl]);

	if (user === undefined) {
		return (
			<div className={styles.loadingContainer}>
				<CircularProgress size={100} />
			</div>
		);
	}

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Navigate to="/trips" replace />} />

				<Route
					path="/trips"
					element={
						<ProtectedRoute
							currentRoute={routes.trips}
							userAccount={user?.account}
							routes={routes}
							userRoles={user?.groups ?? []}
							element={<TripPage />}
						/>
					}
				/>
				<Route
					path="/trips/:tripId"
					element={
						<ProtectedRoute
							currentRoute={routes.trips}
							userAccount={user?.account}
							routes={routes}
							userRoles={user?.groups || []}
							element={<TripDetails />}
						/>
					}
				/>

				{/* Catch-all redirect */}
				<Route path="*" element={<Navigate to="/trips" replace />} />
			</Routes>
		</BrowserRouter>
	);
};

export default AppRoutes;