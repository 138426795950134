import React from 'react';
import {
	Typography as MuiTypography,
	Menu,
	MenuItem,
	Button,
	Divider,
} from '@mui/material';
import { useTheme as useMuiTheme } from '@mui/material/styles';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { MaxWidthContainer } from '../MaxWidthContainer';
import { CorporateLogo } from '../CorporateLogo';
import { TabMenu, TabMenuEntry } from '../TabMenu';
import { ColdNeutral } from '../../colors';
import styles from './Menubar.module.scss';
import { MenuItemProps } from '@mui/material/MenuItem/MenuItem';

export interface UserMenuItemProps extends MenuItemProps {
	/** A label for the menu item. */
	label: string;
	/** Whether to display a vertical separator after the menu item. */
	showSeparator?: boolean;
}

export type MenubarProps = {
	/** A list containing the menu items to be displayed. */
	menu: TabMenuEntry[];
	/** The index of the currently selected item. */
	selectedItem: number;
	/** A callback function that informs the index of the new selected item. */
	onChange: (selectedItem: number) => void;
	/** Whether the theme logo should be displayed. */
	logo?: boolean;
	/** The username for the currently logged-in user. */
	username?: string;
	userMenus?: UserMenuItemProps[];
	/** Whether to display a vertical separator between the menu items and user information. */
	showSeparator?: boolean;
	/** Customizable greeting text. Defaults to "Hello,". */
	greetingText?: string;
};

/**
 * A component that renders a menubar.
 */
export const Menubar = ({
	menu,
	selectedItem,
	onChange,
	logo,
	username,
	userMenus,
	showSeparator,
	greetingText = 'Hello,', // Default value
}: MenubarProps) => {
	const theme = useMuiTheme();

	const themeStyle = {
		'--primary-color': theme.palette.primary.main,
		'--cold-neutral-color': ColdNeutral[500],
		fontFamily: theme.typography.fontFamily,
		fontSize: theme.typography.fontSize,
		lineHeight: '16px',
		letterSpacing: '1.25px',
	} as React.CSSProperties;

	const separatorColor = {
		...(showSeparator ? { backgroundColor: ColdNeutral[200] } : {}),
	};

	const themeStyleIcon = {
		'--primary-color': theme.palette.primary.main,
		'--cold-neutral-color': ColdNeutral[500],
		fontFamily: theme.typography.fontFamily,
		fontSize: theme.typography.fontSize,
		lineHeight: '16px',
		letterSpacing: '1.25px',
		color: theme.palette.primary.main,
	} as React.CSSProperties;

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<MaxWidthContainer>
			<div className={styles.container}>
				<div className={styles.logoContainer}>{logo && <CorporateLogo />}</div>
				<div className={styles.main}>
					<div className={styles.menu}>
						<TabMenu
							menu={menu}
							selectedItem={selectedItem}
							onChange={onChange}
						/>
					</div>
					{username && (
						<div className={styles.userInfo}>
							<div className={styles.separatorContainer}>
								<div
									className={styles.separator}
									style={{ ...themeStyle, ...separatorColor }}
								/>
							</div>
							<MuiTypography
								component="span"
								className={styles.user}
								sx={themeStyle}
							>
								{greetingText}&nbsp;
							</MuiTypography>
							{userMenus && (
								<>
									<MuiTypography
										component="span"
										className={styles.userHighlight}
										sx={themeStyle}
									>
										{username}
									</MuiTypography>
									<Button
										id="basic-button"
										aria-controls={open ? 'basic-menu' : undefined}
										aria-haspopup="true"
										aria-expanded={open ? 'true' : undefined}
										onClick={handleClick}
										className={styles.userIcon}
										sx={themeStyleIcon}
									>
										<PersonOutlineIcon />
									</Button>
									<Menu
										id="basic-menu"
										anchorEl={anchorEl}
										open={open}
										onClose={handleClose}
										MenuListProps={{
											'aria-labelledby': 'basic-button',
										}}
									>
										{userMenus.map((um, index) => (
											<>
												<MenuItem
													{...um}
													id={'userMenu-' + index}
													key={'userMenu-' + index}
													onClick={(event) => {
														handleClose();
														if (um.onClick) {
															um.onClick(event);
														}
													}}
												>
													{um.label}
												</MenuItem>
												{um.showSeparator ? <Divider /> : null}
											</>
										))}
									</Menu>
								</>
							)}
							{!userMenus && (
								<>
									<MuiTypography
										component="span"
										className={styles.userHighlight}
										sx={themeStyle}
									>
										{username}
									</MuiTypography>
									<MuiTypography
										component="span"
										className={styles.userIcon}
										sx={themeStyle}
									>
										<PersonOutlineIcon />
									</MuiTypography>
								</>
							)}
						</div>
					)}
				</div>
			</div>
		</MaxWidthContainer>
	);
};
