import React, { useEffect, useRef } from 'react';
// @ts-ignore
import Plotly from 'plotly.js-dist';

//This component uses the "plotly.js-dist" library directly instead of using the react wrapper because the map type "scattermap" is not available in the wrapper
const RenderTripMap = ({ data }: { data: any }) => {
  const plotRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      Plotly.Plots.resize(plotRef.current);
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (data?.traces && data?.layout) {
      Plotly.newPlot(plotRef.current, data.traces, data.layout);
    }

    return () => {
      if (plotRef?.current) {
        Plotly.purge(plotRef.current);
      }
    };
  }, [data]);

  return <div ref={plotRef} />;
};

export default RenderTripMap;
