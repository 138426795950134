import React, {useState, useEffect} from 'react';
import {Button, Stack, Typography} from '@mui/material';
import {CreateTrip} from './CreateTrip';
import {ColumnConfig, Table, useSnackbar, pixidaTheme} from '@easerill/pixida-group-ui';
import availableVehicles from './availableVehicles.json';
import {Vehicle, Trip, ListTripsResponse, UserRole} from '@sumo/types';

import {listTrips} from '../../api/trip';
import {Link} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './TripPage.module.scss';
import useStateAuth from '../../hooks/state/useStateAuth';
import {useSearchParams} from 'react-router-dom';
import {DriverTripPage} from '../Driver/DriverTripPage';

export const getVehicleDescription = (vehicleId: string, vehicles: Vehicle[]): string => {
	const vehicle = vehicles.find((v) => v.number === vehicleId);

	if (vehicle) {
		const typeCapitalized = vehicle.type === 'bus' ? 'Bus' : 'Tram';
		return `${vehicle.number} ${typeCapitalized} ${vehicle.city}`;
	}

	return vehicleId;
};

const getHighestRole = (userRoles: string[]): UserRole => {
	const roleHierarchy: UserRole[] = ['Root', 'Admin', 'FleetManager', 'Technician', 'FleetUser', 'Driver'];

	for (const role of roleHierarchy) {
		if (userRoles.includes(role)) {
			return role;
		}
	}

	return 'Driver';
};

export const TripPage = () => {
	const [openModal, setOpenModal] = useState(false);
	const [trips, setTrips] = useState<Trip[]>([]);
	const [Loading, setLoading] = useState(true);
	const {triggerSnackbar} = useSnackbar();
	const {user} = useStateAuth();
	const [searchParams] = useSearchParams();
	const driverId = searchParams.get('driverId');

	let isDriver = false;
	let isTechnician = true;
	if (user) {
		isDriver = getHighestRole(user.groups) === 'Driver';
		isTechnician = getHighestRole(user.groups) === 'Technician';
	}
	
	let filteredVehicles = availableVehicles.filter(
		(vehicle) => vehicle.customerId === user?.customerId
	);
	
	if (user?.account === "pilabs") {
		filteredVehicles = availableVehicles;
	}

	const handleOpenModal = () => setOpenModal(true);
	const handleCloseModal = () => setOpenModal(false);
	const handleTripCreated = () => {
		setOpenModal(false);
		loadTrips();
	};

	const loadTrips = () => {
		setLoading(true);
		listTrips()
			.then((fetchedTrips: ListTripsResponse) => {
				setTrips(fetchedTrips.trips);
			})
			.catch(() => {
				triggerSnackbar('Es gab einen Fehler beim Abrufen der Fahrten', 'error');
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		loadTrips();
	}, []);

	const technicianTableConfig: ColumnConfig<Trip>[] = [
		{
			id: 'name',
			label: 'Fahrtenname',
			width: '15%',
			render: (rowData) => {
				return (
					<span
						className={styles.tripNameCell}
						style={{color: pixidaTheme.palette.primary.main}}
					>
						<Link to={`/trips/${rowData.data.tripId}`}>{rowData.data.name}</Link>
					</span>
				);
			}
		},
		{
			id: 'assignedVehicle',
			label: 'Fahrzeug',
			width: '20%',
			render: (rowData) => (
				<span>{getVehicleDescription(rowData.data.assignedVehicle, filteredVehicles)}</span>
			)
		},
		{
			id: 'startTime',
			label: 'Startzeit',
			width: '15%',
			render: (rowData) => (
				<span>{new Date(rowData.data.startTime).toLocaleString('de-DE')}</span>
			)
		},
		{
			id: 'endTime',
			label: 'Endzeit',
			width: '15%',
			render: (rowData) => (
				<span>{new Date(rowData.data.endTime).toLocaleString('de-DE')}</span>
			)
		}
	];

	const tableHeaderConfig: ColumnConfig<Trip>[] = [
		{
			id: 'name',
			label: 'Fahrtenname',
			width: '20%',
			render: (rowData) => {
				return (
					<span
						className={styles.tripNameCell}
						style={{color: pixidaTheme.palette.primary.main}}
					>
						<Link to={`/trips/${rowData.data.tripId}`}>{rowData.data.name}</Link>
					</span>
				);
			}
		},
		{
			id: 'scoreTotal',
			label: 'Fahrtenbewertung',
			width: '10%',
			render: (rowData) => <span>{rowData.data.scorePercent?.toFixed(0)} %</span>
		},
		{
			id: 'driverName',
			label: 'Fahrername',
			width: '20%',
			render: (rowData) => (
				<Link
					to={`/trips?driverId=${rowData.data.driverId}`}
					style={{
						color: pixidaTheme.palette.primary.main,
						textDecoration: 'none'
					}}
				>
					{rowData.data.driverName || 'Unknown Name'}
				</Link>
			)
		},
		{
			id: 'assignedVehicle',
			label: 'Fahrzeug-ID',
			width: '10%',
			render: (rowData) => (
				<span>{getVehicleDescription(rowData.data.assignedVehicle, filteredVehicles)}</span>
			)
		},
		{
			id: 'startTime',
			label: 'Startzeit',
			width: '20%',
			render: (rowData) => (
				<span>{new Date(rowData.data.startTime).toLocaleString('en-GB')}</span>
			)
		},
		{
			id: 'endTime',
			label: 'Endzeit',
			width: '20%',
			render: (rowData) => (
				<span>{new Date(rowData.data.endTime).toLocaleString('en-GB')}</span>
			)
		}
	];

	const getTableConfig = () => {
		if (isTechnician) {
			return technicianTableConfig;
		}
		return tableHeaderConfig;
	};

	const content = (
		<>
			{Loading ? (
				<div style={{display: 'flex', justifyContent: 'center', padding: 16}}>
					<CircularProgress color="inherit" />
				</div>
			) : (
				<>
					{trips.length > 0 ? (
						<Table
							title=""
							columnsConfig={getTableConfig()}
							data={trips}
							rowsPerPageOptions={[10, 25, 50, 100]}
							showBorder={false}
							hideToolbar={true}
							isLoading={Loading}
							defaultOrderBy="name"
							defaultOrder="asc"
						/>
					) : (
						<div style={{padding: 16}}>Keine Fahrten gefunden.</div>
					)}
				</>
			)}
		</>
	);

	if (driverId || isDriver) {
		return <DriverTripPage />;
	} else if (isTechnician) {
		return (
			<div>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					sx={{padding: 2}}
				>
					<Typography variant="h4">Fahrten</Typography>
				</Stack>
				{content}
			</div>
		);
	}
	return (
		<div>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				sx={{padding: 2}}
			>
				<Typography variant="h4">Fahrten</Typography>
				<Button variant="contained" onClick={handleOpenModal}>
					Fahrt erstellen
				</Button>
			</Stack>
			{content}
			<CreateTrip
				open={openModal}
				onCancel={handleCloseModal}
				onSave={handleTripCreated}
				vehicles={filteredVehicles}
				setTrips={setTrips}
			/>
		</div>
	);
};
