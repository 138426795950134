import React, { useEffect, useState } from 'react';
import { Line } from 'recharts';
import { LineChart, XAxis, YAxis, Tooltip } from 'recharts';
import useStateAuth from '../../../hooks/state/useStateAuth';
import { listTrips } from '../../../api/trip';
import { Box, Typography } from '@mui/material';
import GaugeChart from 'react-gauge-chart';
import { useSearchParams } from 'react-router-dom';

interface ScoreTrendData {
    date: string;
    score: number;
}

const styles = {
    container: {
        textAlign: 'center' as const,
        padding: '20px',
    },
    gaugeContainer: {
        width: '100%',
        maxWidth: '300px',
        margin: '0 auto 20px'
    },
    scoreText: {
        fontSize: '32px',
        fontWeight: 'bold',
        marginTop: '-20px',
        marginBottom: '4px'
    },
    label: {
        fontSize: '14px',
        color: '#666',
        textTransform: 'uppercase' as const,
        letterSpacing: '1px'
    },
    chartContainer: {
        marginTop: '20px',
        background: '#fafafa',
        borderRadius: '12px',
        padding: '16px'
    },
    chartTitle: {
        fontSize: '20px',
        fontWeight: 500,
        color: '#333',
        marginBottom: '16px',
        textAlign: 'left' as const
    },
    averageLabel: {
        fontSize: '14px',
        color: '#666',
        marginTop: '8px'
    }
};


const getScoreConfig = (score: number) => {
    if (score >= 80) return { color: '#4caf50', label: 'Ausgezeichnet' };
    if (score >= 40) return { color: '#90a4ae', label: 'Zufriedenstellend' };
    return { color: '#f44336', label: 'Verbesserungswürdig' };
};

export const DriverScore = () => {
    const { user } = useStateAuth();
    const [monthlyScore, setMonthlyScore] = useState(0);
    const [scoreTrend, setScoreTrend] = useState<ScoreTrendData[]>([]);
    const [searchParams] = useSearchParams();
    const driverId = searchParams.get('driverId') || user?.username;

    useEffect(() => {
        if (driverId) {
            loadScoreData();
        }
    }, [driverId]);

    const loadScoreData = async () => {
        try {
            const response = await listTrips();
            let userTrips = response.trips;

            // Filter trips if driverId is provided
            if (driverId) {
                userTrips = response.trips.filter(trip =>
                    trip.driverId === driverId
                );
            }

            const scores = userTrips.map(trip => trip.scorePercent || 0);
            const average = scores.length > 0
                ? scores.reduce((a, b) => a + b, 0) / scores.length
                : 0;
            setMonthlyScore(Math.round(average));

            const trendData = userTrips
                .slice(-7)
                .map(trip => ({
                    date: new Date(trip.startTime).toLocaleDateString('de-DE', {
                        month: 'numeric',
                        day: 'numeric'
                    }),
                    score: trip.scorePercent || 0
                }));
            setScoreTrend(trendData);
        } catch (error) {
            console.error('Error loading score data:', error);
        }
    };


    const scoreConfig = getScoreConfig(monthlyScore);

    return (
        <Box sx={styles.container}>
            <div style={styles.gaugeContainer}>
                <GaugeChart
                    id="score-gauge"
                    nrOfLevels={3}
                    percent={monthlyScore / 100}
                    colors={['#f44336', '#90a4ae', '#4caf50']}
                    arcWidth={0.3}
                    arcPadding={0.02}
                    cornerRadius={5}
                    needleColor="#666"
                    needleBaseColor="#666"
                    hideText={true}
                />
                <Typography
                    style={styles.scoreText}
                    sx={{ color: scoreConfig.color }}
                >
                    {monthlyScore}
                </Typography>
                <Typography style={styles.averageLabel}>
                    Monatlicher Durchschnitt
                </Typography>
                <Typography
                    style={styles.label}
                    sx={{ color: scoreConfig.color }}
                >
                    {scoreConfig.label}
                </Typography>
            </div>

            <div style={styles.chartContainer}>
                <Typography style={styles.chartTitle}>
                    Bewertungsverlauf
                </Typography>
                <LineChart
                    width={300}
                    height={150}
                    data={scoreTrend}
                    margin={{ top: 5, right: 10, left: -20, bottom: 5 }}
                >
                    <XAxis
                        dataKey="date"
                        tick={{ fontSize: 12 }}
                        tickLine={false}
                        axisLine={false}
                    />
                    <YAxis
                        domain={[0, 100]}
                        tick={{ fontSize: 12 }}
                        tickLine={false}
                        axisLine={false}
                    />
                    <Tooltip
                        contentStyle={{
                            background: '#fff',
                            border: 'none',
                            borderRadius: '8px',
                            boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                        }}
                        formatter={(value: number) => [`${value}%`, 'Bewertung']}
                        labelFormatter={(label) => `Datum: ${label}`}
                    />
                    <Line
                        type="monotone"
                        dataKey="score"
                        stroke={scoreConfig.color}
                        strokeWidth={3}
                        dot={{ fill: scoreConfig.color, r: 6 }}
                        activeDot={{ r: 8, fill: scoreConfig.color }}
                    />
                </LineChart>
            </div>
        </Box>
    );
};